<template>
  <cropper
    v-if="inputType === 'image'"
    :prop_aspectRatio="$store.getters['page/chatbotSettings'].aspectRatio"
    v-model="$v.inputValue.$model"
    @input="valueChanged"
  >
  </cropper>

  <div
    v-else-if="inputType === 'chatbot-card'"
    style="display:flex; justify-content: center; margin: 80px auto; flex-wrap: wrap;"
  >
    <p style="width: 100%; font-size: 18px; text-align: center; margin: 20px auto;">
      معاينة البطاقة الرئيسية
    </p>
    <listTemplate
      v-model="$v.inputValue.$model"
      :value="value"
      @input="valueChanged"
    ></listTemplate>
  </div>

  <div v-else class="action-field rtl">
    <div class="m-field-label">
      <label class="label">{{ title }}:</label>
      <div v-if="typeof tooltips !== 'undefined'" class="m-tooltip">
        <img
          v-if="tooltips.text"
          class="big-tabs__help-icon"
          src="../../assets/svg/icon.svg"
          alt="Info icon"
          width="15"
          height="15"
        />
        <span class="tooltip-content"
          >{{ tooltips.text }} <a :href="tooltips.link">{{ tooltips.linkText }}</a></span
        >
      </div>
    </div>
    <div
      class="m-field-input"
      :class="{ ltr: inputType === 'switch' }"
      :style="fieldDirection ? `direction: ${fieldDirection}` : ``"
    >
      <!-- <input :type="inputType" name="" v-model="inputValue" @input="valueChanged($event.target.value)"> -->
      <b-input
        v-if="inputType === 'text' || inputType === 'integer' || inputType === 'textarea'"
        :type="inputType"
        name=""
        v-model.trim="$v.inputValue.$model"
        :maxlength="maxlength"
        @input="valueChanged"
      ></b-input>

      <b-select
        v-if="inputType === 'select' && inputFor === 'currency'"
        :loading="loading"
        expanded
        placeholder="Select a name"
        :value="inputValue"
        v-model="$v.inputValue.$model"
        @input="valueChanged"
      >
        <option v-for="option in options" :value="option.code" :key="option.code">
          {{ option.name }}
        </option>
      </b-select>

      <b-select
        v-if="inputType === 'select' && inputFor !== 'currency'"
        :loading="loading"
        expanded
        placeholder="Select a name"
        :value="inputValue"
        v-model="$v.inputValue.$model"
        @input="valueChanged"
      >
        <option v-for="(option, index) in options" :value="option.id" :key="index">
          {{ option.name }}
        </option>
      </b-select>

      <multiselect
        v-if="inputType === 'multi-select'"
        style="direction: rtl;"
        :loading="loading"
        placeholder="اختر فئة واحدة على الأقل للمنتج"
        v-model="$v.inputValue.$model"
        @input="valueChanged"
        :multiple="true"
        :close-on-select="false"
        track-by="id"
        label="name"
        selectLabel=""
        :taggable="true"
        selectedLabel="تم اختيارها"
        deselectLabel="اضغط لاستبعاد هذه الفئة"
        noOptions="لم يتم إدخال فئات بعد"
        :options="options"
      >
        <span slot="noOptions">لم يتم إدخال فئات حتى الآن</span>
      </multiselect>

      <b-taginput
        v-else-if="inputType === 'tags'"
        :confirm-key-codes="[13, 9]"
        v-model="$v.inputValue.$model"
        @input="valueChanged"
      >
      </b-taginput>

      <b-switch
        v-if="inputType === 'switch'"
        :value="inputValue"
        v-model="inputValue"
        @input="valueChanged"
        >{{ inputValue ? switchOptins.trueValue : switchOptins.falseValue }}</b-switch
      >

      <p v-if="$v.inputValue.$error && !$v.inputValue.required" class="input-notifier">
        يجب تعبئة هذا الحقل
      </p>
      <p
        v-else-if="inputIsInteger && $v.inputValue.$error && !$v.inputValue.integer"
        class="input-notifier"
      >
        يجب إدخال أرقام فقط
      </p>
    </div>
  </div>
</template>

<script>
import cropper from "./ImageInput";
import listTemplate from "../chatbot/utilityComponents/listTemplate";
import Multiselect from "vue-multiselect";
import { EventBus } from "../../utils/EventBus";

function isInteger(condition) {
  return value => {
    return condition ? /^[0-9\u0660-\u0669]+$/.test(value) : true;
  };
}

const isRequired = (condition, inputType) => value => {
  if (condition) {
    switch (inputType) {
      case "tags":
      case "categories":
      case "multi-select":
        return value != null ? value.length !== 0 : false;
      default:
        return value != null ? value !== "" : false;
    }
  } else {
    return true;
  }
};

export default {
  name: "inputFieldComponent",
  props: {
    fieldDirection: String,
    inputFor: {
      type: String,
      default: "category"
    },
    inputType: {
      type: String,
      default: "text"
    },
    switchOptins: {
      type: Object,
      default() {
        return {
          trueValue: "متاح",
          falseValue: "غير متاح"
        };
      }
    },
    optionsUrl: String,
    categoryId: String,
    title: {
      type: String,
      required: true
    },
    tooltips: {
      type: Object,
      default: function() {
        return {
          text: "",
          link: "",
          linkText: ""
        };
      }
    },
    inputIsRequired: {
      type: Boolean,
      default: false
    },
    inputIsInteger: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    value: {
      type: [String, Array, Number, Object, Boolean],
      default: function() {
        return null;
      }
    },
    maxlength: Number
  },
  components: {
    cropper,
    Multiselect,
    listTemplate
  },
  data() {
    const initialValue = this.value != null ? this.value : this.inputType === "tags" ? [] : "";
    return {
      inputValue: initialValue,
      options: [],
      loading: false,
      numberRegex: /^[0-9\u0660-\u0669]+$/,
      currencies: [
        { name: "أوقية موريتانية", code: "MRO" },
        { name: "جنيه سوداني", code: "SDG" },
        { name: "جنيه مصري", code: "EGP" },
        { name: "درهم إماراتي", code: "AED" },
        { name: "درهم مغربي", code: "MAD" },
        { name: "دولار أمريكي", code: "USD" },
        { name: "دينار أردني", code: "JOD" },
        { name: "دينار بحريني", code: "BHD" },
        { name: "دينار تونسي", code: "TND" },
        { name: "دينار جزائري", code: "DZD" },
        { name: "دينار عراقي", code: "IQD" },
        { name: "دينار كويتي", code: "KWD" },
        { name: "دينار ليبي", code: "LYD" },
        { name: "ريال سعودي", code: "SAR" },
        { name: "ريال عماني", code: "OMR" },
        { name: "ريال قطري", code: "QAR" },
        { name: "ريال يمني", code: "YER" },
        { name: "رينغيت", code: "MYR" },
        { name: "سابقًا جنيه فلسطيني", code: "PP" },
        { name: "شلن صومالي", code: "SOS" },
        { name: "فرنك جيبوتي", code: "FDJ" },
        { name: "فرنك قمري", code: "KMF" },
        { name: "ليرة تركية", code: "TRY" },
        { name: "ليرة سورية", code: "SYP" },
        { name: "ليرة لبنانية", code: "LBP" },
        { name: "يورو", code: "EUR" }
      ]
    };
  },
  methods: {
    /**
     * NOTE: Here we change Arabic numbers to English numbers and emmit them
     * On value changed:
     * check if the input type is:
     *  -multi-select ( for category selection ): return the array of selected categories
     *  -tags, image, switch: return the value immediately
     *  -text:
     *    if the input is integer:
     *      replace any arabic number with an english one.
     *    else:
     *      return the value as it is.
     */
    valueChanged() {
      let finalValue = null;
      if (this.inputType === "multi-select") {
        finalValue = this.inputValue.map(category => {
          return { id: category.id, name: category.name };
        });
      } else if (
        this.inputType === "tags" ||
        this.inputType === "image" ||
        this.inputType === "switch"
      ) {
        // array or boolean values
        finalValue = this.inputValue;
      } else if (this.inputIsInteger) {
        finalValue = this.inputValue.replace(/[\u0660-\u0669]/g, c => c.charCodeAt(0) - 0x0660);
        finalValue = parseInt(finalValue);
      } else {
        finalValue = this.inputValue;
      }
      this.$emit("input", finalValue);
    }
  },
  created() {
    EventBus.$on("SUBMIT_ITEM", () => {
      this.$v.$touch();
    });
    if (this.optionsUrl != null && this.optionsUrl !== "") {
      this.loading = true;
      this.$api
        .customRequest({
          method: "get",
          url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/${this.optionsUrl}`,

          responseType: "json"
        })
        .then(({ data }) => {
          if (!data.categories || data.categories.length === 0) {
            this.$buefy.toast.open({
              duration: 5000,
              message: "لم يتم إضافة فئات حتى الآن",
              position: "is-top",
              type: "is-warning"
            });
          }
          if (this.categoryId) {
            data.categories = data.categories.filter(
              cat => cat.id !== this.categoryId && cat.parent_category !== this.categoryId
            );
          }
          if (this.inputFor === "category") {
            this.options = data.categories.map(option => {
              if (option.id === `root${this.$store.getters["page/id"]}`) {
                option.name = "( إضافة هذه الفئة كفئة رئيسية )";
              }
              return option;
            });
          } else {
            this.options = data.categories.map(option => {
              if (option.id === `root${this.$store.getters["page/id"]}`) {
                option.name =
                  this.$store.getters["page/botRecipe"] === "store"
                    ? "منتج رئيسي"
                    : (this.$store.getters["page/botRecipe"] === "store") === "services"
                    ? "خدمة رئيسية"
                    : "طبق رئيسي";
              }
              return option;
            });
          }
        })
        .catch(error => {
          console.log(error);
          this.options = ["أحذية", "رجالي", "نسائي"];
        })
        .finally(() => {
          this.loading = false;
        });
    } else {
      switch (this.inputFor) {
        case "aspectRatio":
          this.options = [
            {
              name: "مستطيلة",
              id: "horizontal"
            },
            {
              name: "مربعة",
              id: "square"
            }
          ];
          break;
        case "currency":
          this.options = this.currencies;
          break;
      }
    }
  },
  validations() {
    return {
      inputValue: {
        required: isRequired(this.inputIsRequired, this.inputType),
        integer: isInteger(this.inputIsInteger)
      }
    };
  }
};
</script>

<style lang="scss">
.m-field-input {
  .multiselect {
    .multiselect__tag {
      color: #4a4a4a;
      background: #f5f5f5;
      .multiselect__tag-icon {
        border-radius: 0px;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        &::after {
          color: #4a4a4a;
        }
        &:hover {
          background: #ff6a6a;
          color: white !important;
        }
      }
    }
    .multiselect__select {
      &::before {
        top: 86%;
      }
    }
    .multiselect__content-wrapper {
      .multiselect__content {
        .multiselect__element {
          .multiselect__option--highlight {
            background: dodgerblue;
          }
          .multiselect__option--selected {
            background: #ff6a6a;
            color: white;
            &::after {
              color: white;
            }
          }
        }
      }
    }
  }
}
</style>
