<template>
  <div class="list-template">
    <div
      class="image"
      @click="$refs.listCardImage.click()"
      :style="`background-image: url(${img_url})`"
      :class="{ empty: file == null && img_url == null }"
    >
      <input
        id="inputFile"
        style=" opacity: 0; height: 0px; width: 0px;"
        type="file"
        name="file"
        ref="listCardImage"
        accept="image/png, image/jpeg"
        @change="onFileSelected"
      />
      <div v-if="loading" class="m-progress-container">
        <div class="m-progress-bar" :style="`width: ${progress}%;`"></div>
      </div>
    </div>

    <div class="card-title">
      {{ title }}
    </div>

    <div class="card-buttons">
      <input
        class="message_template_input"
        type="text"
        minlength="1"
        maxlength="20"
        v-model="startText_local"
        @input="$emit('START_BUTTON_CHANGED', $event.target.value)"
      />

      <input
        v-if="showContactUsButton != null"
        class="message_template_input"
        type="text"
        minlength="1"
        maxlength="20"
        v-model="contactText_local"
        @input="$emit('CONTACT_BUTTON_CHANGED', $event.target.value)"
      />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("recipesConfig");

export default {
  props: {
    image: {
      type: String,
      required: true
    },
    contactText: String,
    startText: String
  },
  data() {
    return {
      file: null,
      img_url: this.$store.getters["page/picture"],
      loading: false,
      progress: 0,
      base64Image: "",
      fileTypes: ["image/jpeg", "image/pjpeg", "image/png"],
      startText_local: null,
      contactText_local: null
    };
  },
  computed: {
    ...mapGetters(["welcomeCard"]),
    title() {
      // TODO read from the store the title of the card.
      return this.$store.getters["page/name"];
    },
    showContactUsButton() {
      return this.$getAtPath(["contact", "initiated"], this.$store.getters["page/services"]);
    }
  },
  methods: {
    validateFileType(file) {
      for (var i = 0; i < this.fileTypes.length; i++) {
        if (file.type === this.fileTypes[i]) {
          return true;
        }
      }
      return false;
    },
    upload() {
      const formData = new FormData();
      formData.append("pageId", this.$store.getters["page/id"]);
      formData.append("userId", this.$store.getters["user/id"]);
      formData.append("image", this.file, this.file.name);
      this.loading = true;
      this.progress = 0// eslint-disable-line
      const option = {
        baseURL: `https://images.services.mujeeb.ai/image`,
        method: "post",
        responseType: "json",

        data: formData,
        onUploadProgress: progressEvent => {
          this.progress = Math.round((progressEvent.loaded * 100.0) / progressEvent.total);
        }
      };
      return this.$api
        .customRequest(option)
        .then(({ data }) => {
          this.img_url = data.img.CDN_url;
          this.$emit("IMAGE_CHANGED", this.img_url);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onFileSelected(e) {
      this.file = null;
      this.base64Image = "";
      this.img_url = "";
      // get file object
      this.file = this.$refs.listCardImage.files[0];
      if (this.file) {
        // check if the file has an accepted extension
        if (!this.validateFileType(this.file)) {
          // TODO alert the user to enter the right type
          this.file = null;
          return;
        }
        // get base64 representation of the file
        const reader = new FileReader();
        reader.onload = e => {
          this.base64Image = e.target.result;
          this.upload();
        };
        reader.readAsDataURL(this.file);
      }
    }
  },
  created() {
    if (this.image != null && this.image !== "") {
      this.img_url = this.image;
    } else {
      this.img_url = this.$store.getters["page/picture"];
    }
    this.startText_local = this.startText;
    this.contactText_local = this.contactText;
  }
};
</script>

<style lang="scss" scoped>
.list-template {
  margin-bottom: 60px;
  width: 250px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  .image {
    width: 100%;
    height: 250px;
    background-position: 50% 50%;
    // background-image: url("https://i.imgur.com/BdYpDAV.png");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: pointer;
    &:hover {
      border: 2px dashed dodgerblue;
    }
    &.empty {
      border: 2px dashed #ff5678;
    }
    .m-progress-container {
      width: 90%;
      margin: auto;
      .m-progress-bar {
        height: 5px;
        background: dodgerblue;
      }
    }
  }
  .card-title {
    text-align: right;
    padding: 5px;
  }
  .card-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 10px;
    .card-button {
      height: 40px;
      padding: 5px;
      color: black;
      background: rgb(214, 214, 214);
      margin-bottom: 5px;
      border-radius: 6px;
      width: 100%;
      text-align: center;
    }
  }

  .message_template_input {
    width: 100%;
    display: block;
    margin-bottom: 10px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid gray;
    height: 35px;
    &:last-child {
      margin: 0px;
    }
  }
}
</style>
