<template>
  <div class="m-generic-container" :class="{ alert: componentState.uploading }">
    <input
      id="inputFile"
      style="opacity: 0; height: 0px;"
      type="file"
      name="file"
      ref="fileInput"
      accept="image/png, image/jpeg"
      @change="onFileSelected"
    />
    <div
      class="image-preview"
      v-if="componentState.newImage && !componentState.cropping && !componentState.uploading"
      style="text-align: center;"
    >
      <img :src="selectedImage" alt="" />
    </div>

    <vue-cropper
      v-if="componentState.cropping"
      ref="cropper"
      :guides="true"
      :view-mode="0"
      drag-mode="move"
      :auto-crop-area="0.5"
      :background="true"
      :aspectRatio="aspectRatio"
      :src="selectedImage"
      :cropBoxMovable="true"
      :containerStyle="{ fillColor: '#FFFFFF' }"
      :alt="alt"
    >
    </vue-cropper>

    <div
      v-else-if="componentState.uploading"
      style="display: flex; height: 150px; justify-content: center; align-items: center; align-content: center;"
    >
      <SpringSpinner :animation-duration="3000" :size="50" color="#d8d8d8"> </SpringSpinner>
    </div>

    <div class="imageUpload-buttons">
      <button class="mujeeb-button" @click="$refs.fileInput.click()">
        <font-awesome-icon icon="image" />
        {{ this.file ? "تغيير الصورة" : "اختر صورة" }}
      </button>
      <div
        v-if="file !== '' && file != null"
        style="display: flex; flex-wrap: wrap; justify-content: center; align-items: center; align-content: center;"
      >
        <button
          v-if="!componentState.cropping"
          class="mujeeb-button"
          @click="componentState.cropping = true"
        >
          <font-awesome-icon icon="edit" />تحديد
        </button>
        <button class="mujeeb-button" v-else @click="cropImage">
          <font-awesome-icon icon="cut" /> اقطع
        </button>
        <button class="mujeeb-button" @click="upload">
          <font-awesome-icon icon="cloud-upload-alt" /> ارفع
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import SpringSpinner from "./../loading/SpringSpinner";
export default {
  name: "imageInput",
  components: {
    VueCropper,
    SpringSpinner
  },
  props: {
    prop_aspectRatio: {
      required: true,
      default: 1.91 / 1
    },
    alertUser: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Object, String]
    }
  },
  data() {
    return {
      file: "",
      fileTypes: ["image/jpeg", "image/pjpeg", "image/png"],
      croppedImage: "",
      alt: " ",
      uploadingProgress: 0,
      selectedImage: null,
      componentState: {
        newImage: false,
        uploading: false,
        cropping: false
      }
    };
  },
  methods: {
    onFileSelected(e) {
      this.selectedImage = null;
      this.croppedImage = "";
      // get file object
      this.file = this.$refs.fileInput.files[0];
      // check if the file has an accepted extension
      if (!this.validateFileType(this.file)) {
        // TODO alert the user to enter the right type
        this.file = "";
        return;
      }
      // get base64 representation of the file
      const reader = new FileReader();
      reader.onload = e => {
        this.selectedImage = e.target.result;
        this.componentState.newImage = true;
        this.componentState.cropping = false;
      };
      reader.readAsDataURL(this.file);
    },
    validateFileType(file) {
      for (var i = 0; i < this.fileTypes.length; i++) {
        if (file.type === this.fileTypes[i]) {
          return true;
        }
      }
      return false;
    },
    validateFileSize(number) {
      if (number < 1024) {
        return { str: number + "bytes", number };
      } else if (number >= 1024 && number < 1048576) {
        return { str: (number / 1024).toFixed(1) + "KB", number };
      } else if (number >= 1048576) {
        return { str: (number / 1048576).toFixed(1) + "MB", number };
      }
    },
    cropImage() {
      this.selectedImage = this.$refs.cropper.getCroppedCanvas().toDataURL("image/png");
      // this.$refs.cropper.replace(this.croppedImage)
      this.componentState.cropping = false;
      this.file = this.dataURItoBlob(this.selectedImage);
    },
    dataURItoBlob(dataURI) {
      return new Promise((resolve, reject) => {
        try {
          var byteString;
          if (dataURI.split(",")[0].indexOf("base64") >= 0) {
            byteString = atob(dataURI.split(",")[1]);
          } else {
            byteString = unescape(dataURI.split(",")[1]);
          }
          // separate out the mime component
          var mimeString = dataURI
            .split(",")[0]
            .split(":")[1]
            .split(";")[0];
          // write the bytes of the string to a typed array
          var ia = new Uint8Array(byteString.length);
          for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          resolve(new Blob([ia], { type: mimeString }));
        } catch (error) {
          reject(error);
        }
      });
    },
    upload() {
      this.componentState.uploading = true;
      this.enableCropping = false;
      return Promise.resolve(this.file)
        .then(fileToUpload => {
          const formData = new FormData();
          formData.append("pageId", this.$store.getters["page/id"]);
          formData.append("userId", this.$store.getters["user/id"]);
          formData.append("image", fileToUpload, this.file.name);
          formData.append("aspectRatio", this.prop_aspectRatio);
          let uploadingProgress = this.uploadingProgress// eslint-disable-line
          const option = {
            //  image-uploader.eu-central-1.elasticbeanstalk.com
            // https://mujeeb-image-uploader.herokuapp.com/image
            baseURL: `https://images.services.mujeeb.ai/image`,
            method: "post",
            responseType: "json",

            data: formData,
            onUploadProgress: progressEvent => {
              uploadingProgress = Math.round((progressEvent.loaded * 100.0) / progressEvent.total);
            }
          };
          return this.$api.customRequest(option);
        })
        .then(({ data }) => {
          this.selectedImage = data.img.CDN_url;
          this.$emit("input", this.selectedImage);
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.componentState.uploading = false;
          this.uploadingProgress = 0;
        });
    }
  },
  created() {
    this.selectedImage = this.value;
    switch (this.prop_aspectRatio) {
      case "square":
        this.aspectRatio = 1;
        break;
      case "horizontal":
        this.aspectRatio = 1.91 / 1;
        break;
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/css/_variables.scss";
.m-generic-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  border: 1px solid rgb(230, 230, 230);
  border-radius: $border-radius-xs;
  background: #e7eff3;
  padding: 15px;
  margin: auto;
  margin-bottom: 30px;
  &.alert {
    box-shadow: $danger-shadow-box;
  }
  div.image-preview {
    display: flex;
    justify-content: center;
  }
  .imageUpload-buttons {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    // padding: 0px 15px 15px 15px;
    button {
      display: flex;
      align-content: center;
      align-items: center;
      min-height: 40px;
      &:first-child {
        i {
          margin: 0px;
          margin-right: 10px;
        }
      }
      &:hover {
        box-shadow: 1px 0 9px $mujeeb-blue;
      }
    }
  }
  .image-container {
    border: 1px dashed gray;
    border-radius: 6px;
    padding: 20px;
    display: flex;
    // min-height: 300px;
    // max-height: 400px;
    // max-height: auto;
    width: 100%;
    cursor: pointer;
    img {
      object-fit: scale-down;
      max-width: 100%;
    }
    .icon {
      font-size: 25px;
      padding: 0px;
      margin: 0px;
      cursor: pointer;
    }
    #inputFile {
      width: 0 !important;
      height: 0 !important;
    }
  }
  .cropper-con {
    max-height: 300px;
    max-width: 600px;
  }
  .image-preview {
    border: none;
    img {
      max-height: 500px;
      object-fit: scale-down;
    }
  }
}
</style>
